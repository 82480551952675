@import "./_variables.scss";

@import url('https://fonts.googleapis.com/css2?family=Fira+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Fira+Code:wght@400;700&family=Fira+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200');

body {
  margin: 0;
}

.container {
  max-width: 1280px;
  margin: 0 auto;
}

.container-padded {
  @extend .container;
  padding: 0 20px;
}


///////////////////
// Cookies Popup //
///////////////////

.cookies-popup {
  position: fixed;
  bottom: $spacing-32;
  right: $spacing-32;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  padding: $spacing-8;

  border: 1px solid $color-gray-light;
  border-radius: 3px;
  background: white;
  
  box-shadow: 0 2px $color-gray-light, 0px 8px 7px 0px #0000000f;

  @media (max-width: 450px) {
    left: $spacing-32;
  }

  .cookies-text {
    width: 240px;
    margin-right: $spacing-24;

    color: $color-text-body;
    font-family: $font-family;
    font-weight: $font-weight-medium;
    font-size: $font-size-14;
    line-height: 1.4;

    a {
      color: $color-text-heading;
      text-underline-offset: 0.2em;
      text-decoration-color: $color-primary;

      &:hover {
        color: $color-primary;
      }
    }
  }

  .cookies-button {
    margin: 0;
    padding: $spacing-8 $spacing-16;
    margin-bottom: $spacing-4;
    
    cursor: pointer;
    border: 2px solid $color-primary;
    border-radius: 3px;
    box-shadow: 0 4px $color-primary;
    background: white;

    color: $color-text-heading;
    font-family: $font-family;
    font-weight: $font-weight-bold;
    font-size: $font-size-16;
    line-height: 1.4;

    &:hover {
      transform: translate(0, 3px);
      box-shadow: 0 1px $color-primary-darker;

      background: $color-primary-lighter;
    }
  }
}


///////////////////
//   Glightbox   //
///////////////////

@import url('../../node_modules/glightbox/dist/css/glightbox.css');

.markdown img {
  cursor: pointer;
}
